import React, { useEffect } from 'react';

const DirectCost = () => {
  useEffect(() => {
    window.location.href = '/cost-to-make-an-app/platforms';
  }, []);
  return (
    <></>
  );
};

export default DirectCost;
